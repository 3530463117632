/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    sticky: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2.5 1A1.5 1.5 0 001 2.5v11A1.5 1.5 0 002.5 15h6.086a1.5 1.5 0 001.06-.44l4.915-4.914A1.5 1.5 0 0015 8.586V2.5A1.5 1.5 0 0013.5 1zM2 2.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5V8H9.5A1.5 1.5 0 008 9.5V14H2.5a.5.5 0 01-.5-.5zm7 11.293V9.5a.5.5 0 01.5-.5h4.293z"/>',
    },
});
